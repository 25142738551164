import { FileDrop } from "react-file-drop";
import React, { DragEvent as ReactDragEvent, useState } from "react";
import { DropzoneOverlay } from "components/ManualUpload/DropzoneOverlay";
import { UploadConfirmModal } from "components/ManualUpload/UploadConfirmModal";
import { getFilesFromDragEvent } from "html-dir-content";
import { useManualUploadManager } from "components/ManualUpload/ManualUploadManager";
import { growlWarning } from "components/common/Growl";
import { HasDocTypeFieldsWithName } from "components/documents/documentUtils";
import { useUploadNotifications } from "components/ManualUpload/UploadNotifications";

const ALLOWED_MIME_TYPES = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/bmp",
  "text/plain",
  "text/csv",
];

interface ManualUploadDropzoneProps {
  enabled?: boolean;
  children?: React.ReactNode;
}

export const ManualUploadDropzone = (props: ManualUploadDropzoneProps) => {
  const [overlayVisible, setOverlayVisible] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [filesToUpload, setFilesToUpload] = useState<Nullable<File[]>>(null);
  const uploadManager = useManualUploadManager();
  useUploadNotifications(); // hook to display the upload notifications

  if (!props.enabled) {
    return <>{props.children}</>;
  }

  const onDragEnter = () => {
    if (!overlayVisible) {
      setOverlayVisible(true);
    }
  };
  const onDragLeave = () => {
    setOverlayVisible(false);
  };

  const onDrop = async (_files: Nullable<FileList>, event: ReactDragEvent<HTMLDivElement>) => {
    setModalVisible(true);
    setOverlayVisible(false);
    const filesRecursive = await getFilesFromDragEvent(event, { recursive: true });
    const filteredFilesRecursive = filesRecursive.filter((file: File) => ALLOWED_MIME_TYPES.includes(file.type));
    if (filesRecursive.length !== filteredFilesRecursive.length) {
      growlWarning(
        `Note - ${filesRecursive.length - filteredFilesRecursive.length} files were ignored because they are not supported types.`
      );
    }
    setFilesToUpload(filteredFilesRecursive);
  };

  const onConfirmImport = (documentType: HasDocTypeFieldsWithName) => {
    uploadManager.addUploadJob([...filesToUpload!], documentType);
    setModalVisible(false);
    setFilesToUpload(null);
  };

  return (
    <FileDrop
      frame={window?.document}
      onFrameDragEnter={onDragEnter}
      onDrop={onDrop}
      onFrameDragLeave={onDragLeave}
      dropEffect="link"
    >
      {overlayVisible && <DropzoneOverlay />}
      <UploadConfirmModal
        files={filesToUpload}
        visible={modalVisible}
        onClose={() => {
          setModalVisible(false);
        }}
        onOK={onConfirmImport}
      />
      {props.children}
    </FileDrop>
  );
};
